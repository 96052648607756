import * as Sentry from "@sentry/nuxt";
import type { SamplingContext } from "@sentry/types";

const {
  public: {
    environment,
    sentry,
  },
} = useRuntimeConfig();

const enabled = !!sentry.enabled && sentry.enabled.toLowerCase() === "true";

Sentry.init( {
  enabled,
  dsn: sentry.dsn,
  environment,
  release: sentry.release,
  tracesSampler: ( context : SamplingContext ) => ( context.location?.href.includes( "_probes" ) ? false : 0.2 ),
} );
